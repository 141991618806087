import {
	ViewListIcon,
} from '@heroicons/react/solid'


const obj = {

	title: "English Converter",
	desc: "Analyze your text and convert in different languages.",
	category: "Programming",
	Icon: ViewListIcon,
	// tags: [],
	permissions: ['user'],
	
	fromColor: "gray-500",
	toColor: "gray-500",

	to: "/ai/englishconverter",
	api: "/ai/englishconverter",

	output: {
		title: "Converted Language",
		desc: "The following key points detected",
		Icon: false,
		color: "blue",
	},

	prompts: [{
		title:"Language Converter",
		desc: "Write details about your Sentence you need to convert below",
		// n: 1,
		prompts: [
			{ 
				title: "Content", 
				attr: "content",  
				value: "", 
				placeholder: "Translate this into any language asked:\n\nConvert in Japanese What rooms do you have available?\n\nあなたが利用可能な部屋は何ですか？\n\nConvert in TElugu What is the name of your mom?\n\nమీ తల్లికి పేరు ఏమిటి?", 
				label: "",
				type: "textarea",
				maxLength: 600,
				// max: 100,
				min: 3,
				required: true,
				error: "",
				example: `Translate this into any language asked:\n\nConvert in Japanese What rooms do you have available?\n\n`,
			},
		],
		example: {
			output: "あなたが利用可能な部屋は何ですか？/n",
			
			// outputs: [
			// 	"The sun is very old, over 4.5 billion years",
			// 	"At 10,000 degrees, sun is also very hot",
			// 	"Gasses called coronal mass ejections erupt from the sun",
			// ],
			// Icon: RefreshIcon,
			color: "blue",
		}
	}]
		
}

export default obj

