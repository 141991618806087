import jobad from './jobad';
import summarize from './summarize';
import codeInterpret from './interpret';
import intro from './intro';
import helloworld from './helloworld';
import example from './example';
import codegen from './codegen';
import englishconverter from './englishconverter';




const TOOLS = [

	codeInterpret,
	intro,
	jobad,
	summarize,
	helloworld,
	example,
	codegen,
	englishconverter,
	
]

export default TOOLS
