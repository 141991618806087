import {
	ViewListIcon,
} from '@heroicons/react/solid'


const obj = {

	title: "Code Generator",
	desc: "Used to generate code for a given problem in desired computer language",
	category: "Programming",
	Icon: ViewListIcon,
	// tags: [],
	permissions: ['user'],
	
	fromColor: "green-500",
	toColor: "yellow-500",

	to: "/ai/codegen",
	api: "/ai/codegen",

	output: {
		title: "Code Generator",
		desc: "The following is the code ",
		Icon: false,
		color: "blue",
	},

	prompts: [{
		title:"Generate Code",
		desc: "Write details about your code below",
		// n: 1,
		prompts: [
			{ 
				title: "Language", 
				attr: "language",  
				value: "", 
				placeholder: "JavaScript...", 
				label: "Which language are you using, will enable markup highlights",
				type: "text",
				maxLength: 40,
				// options: [{ title: "2nd Grader", value: "2nd Grader", desc: "Explain this like I'm 5 years old", Icon: AnnotationIcon },],
				// max: 100,
				min: 3,
				required: true,
				error: "",
				example: `JavaScript`,
			},
			{ 
				title: "Code Intruction", 
				attr: "content",  
				value: "", 
				placeholder: "Write a program to...", 
				label: "Write your coding instruction",
				type: "text",
				maxLength: 200,
				// max: 100,
				min: 3,
				required: true,
				error: "",
				example: `Write a program to calculate the average of 3 numbers`,
			},
		],
		example: {
			output: ``,
			outputs: [
				"The code above is a function definition.",
				"It defines a new function called `HelloWorld` that takes a single argument called `text`",
				"The body of the function is a single line of code that prints out the value of `text` if it is defined, or `Hello World` if it is not defined."
			],
			// Icon: TerminalIcon,
			// color: "gray",
		}
	}]
		
}

export default obj

